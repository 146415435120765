.dashboardButton {
  border: 2px solid transparent;
  color: #1e559a;
  border-radius: 4px;
  padding: 1rem;
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  transition: all 0.2s ease;
  //   grid-row-gap: 1rem;
  align-content: start;
  justify-items: center;
  // margin-bottom: 1rem;
  text-align: center;
  height: 100%;
  text-decoration: none;
  //   color: white;
  font-size: 1.25rem;
  svg {
    font-size: 2rem;
  }
  font-weight: 500;
  transform: scale(0.85);

  &:hover {
    transform: scale(0.95);
    background-color: transparent;
    color: rgba(0, 0, 0, 0.8);
  }
}

.noDecoration {
  text-decoration: none !important;
}


details>summary {
  list-style: none;
}

details>summary::-webkit-details-marker {
  display: none;
}


.noHover:hover {
    &:hover{
      text-decoration: none;
    }

}