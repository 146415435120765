.ares-tvpLayout {
  $primary: rgb(0, 60, 125);
  $navbar-dark-color: white;
  $navbar-dark-hover-color: rgba(white, 0.75);
  $navbar-dark-active-color: white;

  @import '~bootstrap-scss/bootstrap.scss';

  overflow-y: scroll;
  height: 100%;

  .modal-open {
    padding-right: 0 !important;
    overflow: visible;
  }

  body,
  #root {
    height: 100%;
    
  }
  a {
    button {
      cursor: pointer;
    }
  }
  .bg-primary {
    color: white;
    .card-title {
      font-size: 1rem;
    }
  }

  .fixedWidth {
    margin: auto;
    max-width: 1200px;
    padding: 1rem 2rem;
    position: relative;
  }

  .mainContent {
    flex-grow: 1;
    width: 100%;
    background-color: #fefefe;
    font-family: 'Source Sans Pro Regular' sans-serif;
    
  }

  .fixedTop {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
 
  }

  .mainContainer {
    padding-top: 92px;
    width: 100%;
    display: flex;
    flex-flow: column;
    min-height: 100%;
    

    @include media-breakpoint-up(md) {
      padding-top: 60px;
    }

    .submitButton {
      margin: 0 auto;
      display: inherit;
      width: 150px;
      padding: 8px;
    }
  }

  .flex {
    display: flex;
    justify-content: center;
  }

  .form-control.is-valid,
  .was-validated .form-control:valid {
    background-position: right calc(0.375em + 0.1875rem) center;
  }

  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background-position: right calc(0.375em + 0.1875rem) center;
  }

  .navbar {
    padding: 0.5rem 1rem;
    .navbarUsername {
      padding: 0 0.25rem;
    }
    .loginRegister {
      svg {
        margin-right: 0.5rem;
      }
    }
    @include media-breakpoint-up(md) {
      padding: 0px;
      height: 60px;
    }

    .navbar-brand {
      margin-right: 0rem;
    }

    .userDropdown {
      min-width: 250px;
      .userDropdownItem {
        display: grid;
        grid-template-columns: auto 1fr;
        // grid-template-rows: auto auto;
        // grid-template-areas: 'icon user' 'icon name';
        grid-template-areas: 'icon name';
        align-items: center;
        // justify-items: center;
        padding: 0.5rem 1.5rem;
        grid-column-gap: 0.5rem;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;

        &.profile {
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          margin-bottom: 0.5rem;
        }
      }
      .userDropIcon {
        grid-area: icon;
        font-size: 1.2rem;
      }
      .userText {
        grid-area: user;
      }
      .userName {
        grid-area: name;
        white-space: nowrap;
      }
    }
  }

  .page-header {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;
  }

  .thumbnail {
    img {
      width: 100%;
    }
  }

  .sidebarDeclarationButton {
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 1rem 0rem;

    svg {
      margin-right: 0.25rem;
    }
  }

  .rw-widget-input,
  .rw-widget-picker {
    &.invalid {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
      background-repeat: no-repeat;
      background-position: center right calc(0.375em + 0.1875rem);
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  .uppyForm {
    &.invalid {
      border: 1px solid #dc3545;
    }
  }

  .rw-state-disabled > .rw-widget-picker,
  .rw-input[disabled] {
    background-color: #e9ecef;
  }

  .fixedTop {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
    .navbar-nav {
      & > .nav-item {
        margin-right: 5px;
        font-size: 15px;

        &:first-child {
          margin-left: 0;
        }
      }

      .dropdown-menu {
        .dropdown-item {
          font-size: 14px !important;
        }

        a {
          &:focus,
          &:hover {
            text-decoration: none !important;
            outline: 0 !important;

            .dropdown-item {
              background-color: #1e559a !important;
              color: #ffffff !important;
            }
          }
        }
      }
    }
  }

  .avoidWrap {
    display: inline-block;
  }

  .uppy-Dashboard-inner {
    min-height: 100px;
  }


.autologout {
  display: block;
  pointer-events: none;
  padding: 0.5rem;
  background-color: rgba(255, 82, 90, 1);
  box-shadow: 0 4px 30px -4px rgba(0, 51, 90, 0.3) !important;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  z-index: 99999999999;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  line-height: 17px !important;
  
}

.firstColor {
  color: rgb(0, 60, 125);
}

.secondColor {
  color:rgb(85, 85, 85);
}

.thirdColor {
  color: rgb(0, 168, 255);
}
.fourthColor {
  color: rgb(0, 82, 158);
}

.fifthColor {
  color: rgb(169, 226, 255);
}

.sixthColor {
  background-color: rgb(219, 241, 250);
}

.redColor {
  color: #ff0000;
}

.lightBlueColor{
  color: #0283d9;
}

.listIcons li{
  list-style: none;
  background-repeat: no-repeat;
}

.okCircle {
  position: relative;
  
  li::before{
  content: url('./../../../assets//img//brand//pzu///okCircle.png');
  display: inline-block;
  position: absolute;
  left: 1em;
  margin-top: 5px;
  }
}

.ok li{    
      list-style-image: url('./../../../assets//img//brand//pzu///ok.png');
      &:before{
        display: none;
      }
}


.dashedList {
  list-style-type: none;

  &>li::before {
    content: "- ";
  }
}

.iconFromLeft {
  margin-left: 30px;
  font-size: 12px;
  &::before{
    position: absolute;
    left: 0;
  }
}

.moneyIcon {
  &::before{
    content: url('./../../../assets//img//brand//pzu///pln.png');
  }
}

.infoIcon {
  &::before {
    content: url('./../../../assets//img//brand//pzu///info.png');
  }
}


.listUnstyled{
  list-style-type: none;
}

.listArrowRight{
      list-style: none;
      padding: 0px;

    li{
      margin-bottom: 6px;
      padding-left: 20px;
      position: relative;

      &:before{
      content: '\2794';
        left: 0;
        position: absolute;
      }
    }
}


.tableBackgroundHeader{
    background-color: rgb(219, 241, 250);
    
}

.tableBackgroundRow{
  background-color: rgba(0, 0, 0, 0.05);
}


}




